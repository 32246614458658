@import "./vars";
@import "./mixins";

input, select, .drop-down-button {
  width: 100%;
  display: block;
  background: #FFF;
  display: flex;
  height: 1.5rem;
}

input[type="checkbox"] {
  width: auto;
  display: inline;
}
/* INPUTS */
.checkbox:has(input[type="checkbox"]) {
  padding: 2px;
  border: 2px solid $black;
  height: 60px;
  min-width: 60px;
}

input[type="checkbox"].input {
  position: relative;
  height: 26px;
  min-width: 26px;
  top: 6px;
  margin-left: 8px;
  margin-right: 8px;

  &:active {
    color: $pink;
  }

  &:checked {
    color: $pink;
    background-color: $pink;
  }
}

input[type="number"].input {
  // max-width: 80px;
  min-width: 56px;
  text-align: right;

  &.currency {
    min-width: 114px;
    padding-left: 28px;
  }
}

input[type="search"] {
  padding-left: 24px;
  background-image: url("../images/icons/account.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: auto 16px;
}

.input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid $dark-white;
  height: 51px;
  min-width: 120px;
  text-align: left;
  padding: 0px 8px;

  &.text-center {
    text-align: center;
  }

  &.border-pink,
  &.border-primary {
    border-color: $pink;
  }

  &.textarea {
    min-height: 60px;
    max-height: 120px;
    resize: vertical;
  }

  &.white {
    background-color: $white;
  }

  &.short {
    height: 30px;
  }
  &:read-only {
    background-color: $dark-white;
    border-color: $darker-white;
  }

  &.negative {
    color: $pink;
    text-decoration:wavy;
  }

  
}

:required,
.required {
  padding-left: 24px;
}

:required:not(:disabled),
.required:not(:disabled) {
  background-image: url("../images/shapes/star.svg");
  background-repeat: no-repeat;
  background-position: 8px center;
}

/* END INPUTS */
