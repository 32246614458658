.dx-editor-cell.dx-editor-inline-block {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.dx-data-row td.inactive:first-child {
   text-decoration: line-through;

   :hover {
       text-decoration: line-through;
   }
} 
.dx-data-row td.not-included:first-child {
   color: #bcbcbc;
}

.dx-data-row td.inactive:not(:first-child), .dx-data-row td.not-included:not(:first-child) {
   visibility: hidden;
}

.dx-data-row>td:first-child:hover {
    text-decoration: underline;
    cursor: pointer;
}

