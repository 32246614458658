@import "./vars";
@import "./mixins";
/*  DROP DOWNS */

.dropdown-wrapper {
  position: relative;
  width: auto;
  height: auto;

  >a.drop-down {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 64px;
  }

  &.fill {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.active) {
    .dropdown-contents {
      z-index: -100;
      max-height: 0px;
      transition: max-height 0.1s ease-out;
    }
  }

  &.active {
    .dropdown-contents {
      border-width: 1px;
      overflow-x: hidden;
      left: 0px;

      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background: #FFF;
      top: 100%;
      border-radius: 0.5rem;
      border-color: #ccc;
      padding: 0;
      min-width: 300px;
      max-width: 100vw;
      max-height: 32rem;
      width: 100%;
      z-index: 15;
      overflow: hidden;
      overflow-y: auto;
      box-shadow: 0 2px 2px 2px rgba(0,0,0,0.1);
    }

    .dropdown-contents::-webkit-scrollbar {
      display: none;
    }

    .btn.drop-down-button::after {
      transform: rotate(-90deg);
      bottom: 12px;
      transition: all 0.3s;
    }
  }

  .btn.drop-down-button {
    border: 2px solid $dark-white;
    width: calc(100% - 48px);
    min-width: 96px;
    padding: 8px 16px;

    &.border-black {
      border: 4px solid $black;
    }

    &::after {
      content: "";
      display: inline-block;
      background-image: url("../images/shapes/half-rectangle.svg");
      background-size: 24px 24px;
      z-index: 1;
      height: 24px;
      width: 24px;
      position: absolute;
      bottom: 8px;
      right: 8px;
      transition: all 0.2s;
    }

    &:disabled {
      &::after {
        background: transparent;
      }
    }

    &.border-pink,
    &.border-primary {
      border-color: $pink;
    }

    &.border-gold,
    &.border-secondary {
      border-color: $gold;
    }

    &.border-blue {
      border-color: $blue;
    }

    &.white {
      background-color: $white;
      border-color: $light-white;
    }

    &.required {}
  }

  .dropdown-contents {
    margin-top: 6px;
    box-sizing: border-box;
    border: 0px solid $darker-white;
    position: absolute;
    min-width: 10vw;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;

    .drop-down-item {
      display: flex;
      align-items: center;
      padding: 1rem;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: inherit;
      font-family: 'Rubik';
      font-weight: 400;
      transition: all 0.5s;
      font-size: 17px;
      max-height: 20px;

      &:hover {
        color: $black;
      }
    }
  }
}

/* END DROP DOWNS */
