@import "~normalize.css";
@import "./assets/scss/vars";
@import "./assets//scss/mixins";
@import "./assets/scss/buttons";
@import "./assets/scss/dropdowns";
@import "./assets/scss/inputs";
@import "../src/assets/scss/cards";
@import "./assets/scss/tooltips";
@import "./assets/scss/devexpress";

a {
  cursor: pointer;
}

body {
  font-family: "Rubik", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  overflow-x: hidden;
  background-image: url(assets/images/sherpa-bg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100vw;

  &.no-chrome {
    background-image: none;
    background-color: $light-white;

    header,
    footer {
      display: none;
    }
  }
}

.hidden,
[hidden] {
  display: none !important;
}

.standard-background {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    opacity: 1;
    z-index: -1;
    border-radius: 5px;
  }

  &.gray {
    background-color: $light-gray;
  }

  &.padded {
    padding: 16px;
  }
}

/* HEADINGS */

.heading-1,
h1 {
  font-size: 36px;

  @include tablet() {
    font-size: 72px;
  }

  font-family: Rubik,
  serif;
  font-style: normal;
  font-weight: bold;
  line-height: 91px;
  margin: 0px;
  padding: 0px;
}

.heading-2,
h2 {
  font-size: 48px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  line-height: 57px;
  margin: 0px;
  padding: 0px;
}

.heading-3,
h3 {
  font-size: 36px;
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  font-size: 30px;
}

.heading-4,
h4 {
  font-size: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
}

.heading-5,
h5 {
  font-size: 24px;
  font-family: Rubik, serif;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
}

/* END HEADINGS */

.notification {
  position: relative;
  margin-right: 8px;
  width: 16px;
  height: 23px;
  line-height: 24px;
  background-color: #f26363;
  border-radius: 22px;
  text-align: center;
  border: 2px solid white;
  color: white;
  font-size: 16px;
  top: -10px;
  left: -4px;
  display: inline-flex;
  padding-left: 7px;
}

a:not(.btn):link {
  color: $black;
  text-decoration: none;

  &:hover {
    color: $almost-black;
  }
}

a:not(.btn):visited {
  color: $blue;
  text-decoration: none;

  &:hover {
    color: $light-blue;
  }

  &.black {
    color: $black;

    &:hover {
      color: $almost-black;
    }
  }
}

a.btn {
  text-decoration: none;

  &:visited {
    color: $black;
  }
}

/*  TEXT  */

.text {
  &.rubik {
    font-family: "Rubik", serif;
    font-style: normal;
  }

  &.Rubik,
  serif {
    font-family: "Rubik, serif", serif;
    font-style: normal;
  }

  &.no-wrap {
    white-space: nowrap;
  }

  &.text-wrap {
    white-space: break-spaces;
  }

  &.text-primary {
    color: $primary-link-color;
  }

  &.text-ellipsis {
    text-overflow: ellipsis;
  }

  &.text-300 {
    font-weight: 300;
  }

  &.text-400 {
    font-weight: 400;
  }

  &.text-500 {
    font-weight: 500;
  }

  &.text-600 {
    font-weight: 600;
  }

  &.text-700 {
    font-weight: 700;
  }

  &.text-800 {
    font-weight: 800;
  }

  &.text-900 {
    font-weight: 900;
  }

  &.text-upper {
    text-transform: uppercase;
  }

  &.text-lower {
    text-transform: lowercase;
  }

  &.text-italic {
    font-style: italic;
  }

  &.text-center {
    text-align: center;
  }

  &.text-left {
    text-align: left;
  }

  &.text-right {
    text-align: right;
  }

  &.text-black {
    color: $black;
  }

}

/*  END TEXT */

/*  MARGINS  */

.mt-small {
  margin-top: 4px;
}

.mb-small {
  margin-bottom: 4px;
}

.mr-small {
  margin-right: 8px;
}

.ml-small {
  margin-left: 8px;
}

.mt-medium {
  margin-top: 12px;
}

.mb-medium {
  margin-bottom: 12px;
}

.mr-medium {
  margin-right: 24px;
}

.ml-medium {
  margin-left: 24px;
}

.mt-large {
  margin-top: 22px;
}

.mb-large {
  margin-bottom: 22px;
}

.mr-large {
  margin-right: 44px;
}

.ml-large {
  margin-left: 44px;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

/*  END MARGINS  */

/*  PADDING  */

.pt-small {
  padding-top: 2px;
}

.pb-small {
  padding-bottom: 2px;
}

/*  END PADDING */

/* TABLES */

.table {
  padding: 0px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  border-spacing: 0px;

  thead>tr:first-child {

    >th:first-child,
    thead>tr>th:first-child {
      border-top-left-radius: 8px;
    }
  }

  thead>tr:first-child {

    >th:last-child,
    thead>tr>th:last-child {
      border-top-right-radius: 8px;
    }
  }

  tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 4px;
  }

  tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 4px;
  }

  th {
    font-weight: 500;
    white-space: nowrap;

    &.sortable {
      &::after {
        content: " -";
      }

      &.sort {
        font-weight: 800;

        &.asc::after {
          content: " \2191";
        }

        &.desc::after {
          content: " \2193";
        }
      }
    }
  }

  .grid {
    tr:first-child {

      td,
      th {
        border-bottom: 1px solid $dark-white;
      }

      td:not(:last-child),
      th:not(:last-child) {
        border-right: 1px solid $dark-white;
      }
    }

    tr:last-child {

      td:not(:last-child),
      th:not(:last-child) {
        border-right: 1px solid $dark-white;
      }
    }

    tr:not(:first-child):not(:last-child) {

      td,
      th {
        border-bottom: 1px solid $dark-white;
      }

      td:not(:only-child):first-child,
      th:first-child {
        border-right: 1px solid $dark-white;
      }

      td:not(:first-child):not(:last-child),
      th:not(:first-child):not(:last-child) {
        border-right: 1px solid $dark-white;
      }
    }
  }

  &.wide {
    width: 100%;

    &.fixed {
      table-layout: fixed;
    }
  }

  .border {
    &.black {

      th:first-child,
      td:first-child {
        border-left: 1px solid $black;
      }

      th:last-child,
      td:last-child {
        border-right: 1px solid $black;
      }

      &:not(tbody) {
        tr:first-child {

          th,
          td {
            border-top: 1px solid $black;
          }
        }
      }

      &:not(thead) {
        tr:last-child {

          th,
          td {
            border-bottom: 1px solid $black;
          }
        }
      }
    }

    &.pink,
    &.primary {

      th:first-child,
      td:first-child {
        border-left: 1px solid $pink;
      }

      th:last-child,
      td:last-child {
        border-right: 1px solid $pink;
      }

      &:not(tbody) {
        tr:first-child {

          th,
          td {
            border-top: 1px solid $pink;
          }
        }
      }

      &:not(thead) {
        tr:last-child {

          th,
          td {
            border-bottom: 1px solid $pink;
          }
        }
      }
    }

    &.gold,
    &.secondary {

      th:first-child,
      td:first-child {
        border-left: 1px solid $gold;
      }

      th:last-child,
      td:last-child {
        border-right: 1px solid $gold;
      }

      &:not(tbody) {
        tr:first-child {

          th,
          td {
            border-top: 1px solid $gold;
          }
        }
      }

      &:not(thead) {
        tr:last-child {

          th,
          td {
            border-bottom: 1px solid $gold;
          }
        }
      }
    }

    &.blue {

      th:first-child,
      td:first-child {
        border-left: 1px solid $blue;
      }

      th:last-child,
      td:last-child {
        border-right: 1px solid $blue;
      }

      &:not(tbody) {
        tr:first-child {

          th,
          td {
            border-top: 1px solid $blue;
          }
        }
      }

      &:not(thead) {
        tr:last-child {

          th,
          td {
            border-bottom: 1px solid $blue;
          }
        }
      }
    }
  }

  .order-items td {
    background-color: $light-white !important;

    &:not(:first-child) {
      text-align: right;
    }
  }

  thead {
    padding: 0px;
    border-collapse: collapse;

    th,
    td {
      padding: 8px 10px;
      margin: 0px 0px;
    }
  }

  tbody {
    tr:not(.fill):nth-child(even) {
      background-color: $white;
    }

    tr:not(.fill):nth-child(odd) {
      background-color: $light-white;
    }

    td {
      padding: 8px 10px;
      margin: 0px 0px;

      &.word-wrap {
        word-break: break-word;
        width: 30%;
      }
    }
  }

  tr.header {
    font-weight: bold !important;
    text-align: center !important;
    background-color: $light-blue !important;
  }
}

/* END TABLES */

/* FILLS */
.fill {

  &.fill-pink,
  &.fill-primary {
    background-color: $pink;
  }

  &.fill-gold,
  &.fill-secondary {
    background-color: $gold;
  }

  &.fill-gold-accent,
  &.fill-secondary-accent {
    background-color: $gold-accent;
  }

  &.fill-blue {
    background-color: $light-blue;
  }

  &.fill-white {
    background-color: $white;
  }
}

/* END FILLS */

/* FORMS */
.form {
  display: flex;
  flex-direction: column;

  @include tablet() {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

/* END FORMS */
/* FLEX */
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row,
.flex-column {

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-even {
    justify-content: space-evenly;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-start {
    justify-content: start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-around {
    align-items: space-around;
  }

  &.align-between {
    align-items: space-between;
  }

  &.align-even {
    align-items: space-evenly;
  }

  &.align-end {
    align-items: end;
  }

  &.align-start {
    align-items: start;
  }

  &.flex-grow {
    flex-grow: 1;
  }
}

/* END FLEX */

/* SPACER */
.spacer {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;

  @include tablet() {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  height: 1px;
  width: 100%;
  position: relative;
  background-color: $light-blue;
}


.vertical-spacer {
  // margin-top: 12px;
  // margin-bottom: 12px;
  border-radius: 4px;



  width: 1px;
  position: relative;
  z-index: 1;
  background-color: $dark-white;
}

/* END SPACER */

/*NAVBAR*/
.navbar {
  display: flex;
  padding: 0px 20px;
  background: $white;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  flex: none;
  align-items: center;
  height: 74px;
}

a:not(.btn) {
  font-size: 17px;
  font-weight: 400 !important;
  margin-right: 30px;
  position: relative; /* Add this line to position the pseudo-element relative to the anchor */

  &.active:after {
    content: '';
    position: absolute; /* Change to absolute positioning */
    bottom: -2px; /* Position underline 2px below the text */
    left: 0;
    height: 2px;
    width: 100%;
    background: #f26363;
  }
}

.badge {
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;

}

.badge.no-filter {
  img {
    filter: none;
  }
}


.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*END NAVBAR*/

/* INVOICE ENTRY*/

.invoice-card,
.cart-card,
.order-card {
  &.entry {
    min-width: 100%;
  }

  &.history {
    min-width: 30%;

    .invoice-list>div {
      background-color: white;
      padding: 16px;
    }
  }

  .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 32px;
    padding-bottom: 0;
    color: $black;
  }

  .body {
    padding: 10px 32px 32px 32px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $white;

    @include mobile-only() {
      overflow-x: scroll;
    }
  }
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.error {
  color: $pink;
}

/*END INVOICE ENTRY*/

#dashboard {

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 16px;
  color: $black;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $white;
}

/*EDIT SCREENS*/
.edit-screen {
  &.header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 32px;
    color: $black;
  }

  &.body {
    padding: 32px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $white;
  }
}

/*END EDIT SCREENS*/

.dx-list-item:first-of-type {}

.dx-inkripple-wave {
  display: none !important;
}

.dx-inkripple {
  display: none !important;

  &::before {
    content: none !important;
    display: none;
  }

  &::after {
    height: 0px;
    width: 0px;
    animation: none;
  }
}

.footer-logo {
  filter: invert(58%) sepia(31%) saturate(2707%) hue-rotate(321deg) brightness(100%) contrast(91%);
}


.dx-tabs {
  width: auto;
  background: none;

  .dx-tab {
    background: none;

    .dx-tab-text {
      text-transform: none;
      font-size: 16px;
    }

    &.dx-tab-selected {
      &:before {
        display: none;
      }

      .dx-tab-text {


        &:after {
          height: 2px;
          display: block;
          width: 100%;
          background: #f26363;
          border-right: 1px white;
          content: '';
        }
      }
    }

    &.dx-tab-selected::before {
      background-color: $pink;
    }
  }
}

.search-form {
  .dx-field-item-label {
    display: none;
  }

  .dx-button {
    text-shadow: none;
    box-shadow: none;
    margin-top: 12px;

    .dx-button-content {
      padding: 10px 30px;
      background: $pink;
      color: $white;
      text-shadow: none;
      box-shadow: none;
      border-radius: 5px;
    }

    &.dx-state-disabled {
      .dx-button-text {
        color: white;
      }
    }
  }
}

app-supplier-product-card {
  position: relative;
}

.scroll {

  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: scroll;

  .dx-scrollview-content {
    display: flex;
    flex-direction: row;

    .dx-list-item-content {
      min-height: 204px;
      max-width: 468px;
    }

    .dx-list-item:first-of-type {
      margin-top: 0px !important;
    }

    .dx-item.dx-list-item {
      overflow: visible;
      min-width: 430px;
      width: 430px;
      // margin: 10px 10px 40px;
      cursor: default;

      &.dx-state-hover,
      &.dx-state-focused,
      &.dx-state-active {
        background: none !important;
      }
    }
  }

  &.vertical {
    padding-left: 10px;
    .dx-list-item:first-of-type {
      margin-top: 0px;
    }

    .dx-scrollview-content {
      flex-wrap: wrap;
    }

    .dx-item.dx-list-item {
      margin: 0px 0px 28px;
    }

  }

  &.horizontal {
    margin-top: 10px;
    background: #eaeaea;

    .dx-list-item {
      margin-top: 0px;
    }

    .dx-scrollable-content {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888 !important;
        cursor: pointer;
        border-radius: 5px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
      }
    }

    .dx-scrollview-content {
      flex-wrap: nowrap;
      padding: 15px 10px 15px 10px;


      .dx-list-item {
        margin-top: 0px;
      }

      .dx-item.dx-list-item {
        // margin: 10px 10px 40px;
        margin: 0px 0px 0px;
        cursor: default;

        &.dx-state-hover,
        &.dx-state-focused,
        &.dx-state-active {
          background: none !important;
        }
      }
    }
  }
}

.scroll.vertical {

  .dx-list-next-button {
    padding: 5px;
    text-align: center;
    display: block;
    clear: both;
  }

  .centered-columns.dx-item-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dx-list-item-content {
    font-size: inherit;
    padding: 0px;
    overflow: visible;
  }

  .dx-item.dx-list-item {
    cursor: default;

    &.dx-state-hover,
    &.dx-state-focused,
    &.dx-state-active {
      background: none !important;
    }
  }
}

app-product-search {
  .dx-item .dx-box-item {
    flex: none !important;
  }

  .dx-texteditor-input {
    min-width: 0px !important;
    background-color: white;
  }

}


.info-box {
  display: grid;
  grid-template-columns: auto 12px;
  gap: 8px;
  border: 1px solid $blue;
  background-color: $light-blue;
  color: black;
  padding: 8px;
  border-radius: 4px;
}

.avatar-agent {
	clear: both;
	display: block;
	overflow: auto;

	.avatar {
		background: white url(assets/images/avatar.png);
		width: 20px;
		height: 20px;
		background-size: contain;
		background-repeat: no-repeat;
		float: left;
		background-color: white;
		border-radius: 20px;
		padding: 7px;
		box-sizing: content-box;
		background-origin: content-box;
		background-position: 50% 50%;
		border: solid 1px #aaa;
		margin-top: 2px;
	}

	.agent {
		float: left;
		margin-left: 15px;

		.approver-header {

			.approver-name, .change-name {
				clear: both;
				display: block;
				font-weight: 500;
				color: #333;
				font-size: 15px;
			}
		}

		.approver-description {
			clear: both;
			display: block;
			color: #aaa;
			margin-top: 0px;
			font-size: 14px;
		}
	}

	&.timeline {

		.approver-header {

			.approver-name, .change-name {
				clear: both;
				display: block;
				font-weight: 500;
				color: #333;
				font-size: 15px;
			}

			.change-name {
				float: left;
				clear: none;
			}

			.change-date {
				float: right;
				clear: none;
				color: #aaa;
				font-size: 13px;
			}
		}

		.agent {
			float: none;
			margin-left: 50px;
		}
	}
}

.approver-decision {
	clear: both;
	display: block;
	margin-left: 50px;

	.container {
		overflow: auto;
		margin-top: 5px;
		font-size: 14px;

		.status {
			float: left;
			font-weight: 500;
			color: red;

			&.approved {
				color: green;
			}
		}

		.date {
			float: right;
			color: #aaa;
			font-size: 14px;
		}
	}

	.comments {
		clear: both;
		margin-top: 5px;
		font-size: 14px;
		color: #333;
		font-style: italic;
	}
}

.confirm-dialog {
	.header {
		margin-top: 20px;
	}

	.buttons-container {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 0px;
		float: right;

		.btn {
			min-width: 110px;
			padding: 15px 10px;
			display: inline-block;
			margin: 0 5px;
			color: #333;
			border: solid 1px #aaa;
			background: none;
			height: 10px;
			line-height: 10px;
			font-size: 14px;
		}

		.btn.primary {
			background: hsl(0deg, 85%, 67%) !important;
			color: white;
			border: none;
		}

		.dx-button.btn {
			min-width: 110px;
			padding: 15px 10px;
			display: inline-block;
			margin: 0 10px;
			box-sizing: content-box;
			height: 25px;
			line-height: 25px;
			vertical-align: middle;
			padding: 7px 10px;
			margin: 0;
			box-shadow: none;
			margin: 0 5px;
			background: white;

			&.primary {
				.dx-button-content {
					color: white;
				}
			}

			.dx-button-content {
				box-sizing: content-box;
				line-height: 25px;
				height: 25px !important;
				vertical-align: middle;
				text-align: center;
				overflow: auto;
				width: 100%;
				display: inline-block;
				flex: none;
				padding: 0;
				margin: 0;
				clear: none;
				position: initial;
				color: #333;
			}
		}
	}

	.disclaimer {
		display: flex;
		align-items: flex-start; /* Aligns children to the top */
		align-items: center;
		clear: both;
		margin-top: 15px;

		.disclaimer-image {
			margin-right: 10px;
		}

		.disclaimer-text {
			flex-grow: 1;
			font-size: 14px;
			font-weight: 500;
		}
	}
}