@mixin large-desktop() {
    @media  only screen and (min-width: $largeDesktop) {
        @content;
    }
}

@mixin desktop() {
    @media  only screen and (min-width: $desktop) {
        @content;
    }
}

@mixin large-tablet() {
    @media  only screen and (min-width: $largeTablet) {
        @content;
    }
}

@mixin tablet() {
    @media  only screen and (min-width: $tablet) {
        @content;
    }
}

@mixin mobile() {
    @media  only screen and (min-width: $mobile) {
        @content;
    }
}

@mixin mobile-only() {
    @media  only screen and (max-width: $mobile) {
        @content;
    }
}