@import "./vars";
@import "./mixins";
@import "~normalize.css";

/*  BUTTONS */

.btn {
  /// DEFAULTS
  font-family: "Rubik", serif;
  font-weight: 500;
  text-transform: uppercase;
  /// END DEFAULTS
  position: relative;
  width: fit-content;
  min-width: 10rem;
  height: 25px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  border: 0px transparent;
  margin-bottom: 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  white-space: nowrap;
  padding: 1rem;
  background-color: $dark-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
  transition: 0.5s;

  &.text-white {
    color: $white;
  }

  svg {
    max-height: 1.125rem;
  }
  /*Solid Buttons*/
  &.primary {
    background: $pink;
    color: $white;
    border: none;
    box-shadow: none;
    font-size: 16px;
    height: 10px;
    display: inline;
    line-height: 12px;

    &:disabled {
      background-color: $disabled;
      box-shadow: none;
    }

    svg {
      path {
        fill: $black;
      }
    }
  }
  /*Outline Buttons*/
  &.secondary {
    background: $white;
    border: 1px solid;
    box-shadow: none;

    &:disabled {
      background: $white;
      border-color: $disabled;
      color: $disabled;

      svg {
        path {
          fill: $disabled;
        }
      }
    }

    &.black {
      border-color: $black;
    }

    &.pink {
      background-color: $white;
      border-color: $pink;
      color: $pink;

      &:hover {
        background-color: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.transparent {
    background-color: transparent;
  }

  &:disabled {
    background-color: $disabled;
    box-shadow: none;
  }

  &.no-wrap {
    white-space: nowrap;
    min-width: auto;
  }

  &.width-small {
    padding-left: 12px;
    padding-right: 12px;
  }

  &.width-medium {
    padding-left: 32px;
    padding-right: 32px;
  }

  &.width-large {
    padding-left: 42px;
    padding-right: 42px;
  }

  &.width-fill {
    width: 100%;
  }

  &.white {
    background-color: $white;
  }

  &.pink {
    background-color: $pink;
    color: white;
    // background-color: transparent;
    border: 4px solid $pink;
    // color: $pink;

    &.fill {
      background-color: $pink;
      color: $black;
    }

    &:hover {
      background-color: $pink-accent;
    }

    &:active,
    &:focus {
      background-color: $pink-muted;
      border-color: $pink-muted;
    }

    &.accent {
      background-color: $pink-accent;

      &:hover {
        background-color: $pink;
      }
    }

    &.muted {
      background-color: $pink-muted;

      &:hover {
        background-color: $pink-accent;
      }
    }
  }

  &.border-black {
    box-shadow: 0px $button-bottom-border 0px $black;
  }

  &.border-pink,
  &.border-primary {
    box-shadow: 0px $button-bottom-border 0px $pink;
  }

  &.border-pink-accent,
  &.border-primary-accent {
    box-shadow: 0px $button-bottom-border 0px $pink-accent;
  }

  &.border-pink-muted,
  &.border-primary-muted {
    box-shadow: 0px $button-bottom-border 0px $pink-muted;
  }

  &.gold {
    background-color: transparent;
    border: 4px solid $gold;
    color: $gold;

    &.fill {
      background-color: $gold;
      color: $black;
    }

    &:hover {
      background-color: $gold-accent;
    }

    &.accent {
      background-color: $gold-accent;

      &:hover {
        background-color: $gold;
      }
    }

    &.muted {
      background-color: $gold-muted;

      &:hover {
        background-color: $gold-accent;
      }
    }
  }

  &.blue {
    background-color: transparent;
    border: 4px solid $blue;
    color: $blue;

    &:hover {
      background-color: $light-blue;
    }
  }

  &.gray {
    background-color: $darker-white;

    &:hover {
      background-color: $light-white;
    }
  }

  &.border-blue {
    box-shadow: 0px $button-bottom-border 0px $blue;
  }

  &.border-gold,
  &.border-secondary {
    box-shadow: 0px $button-bottom-border 0px $gold;
  }

  &.border-gold-muted,
  &.border-secondary-muted {
    box-shadow: 0px $button-bottom-border 0px $gold-muted;
  }

  &.border-gray {
    box-shadow: 0px $button-bottom-border 0px $dark-white;
  }

  &.border-gray-light {
    box-shadow: 0px $button-bottom-border 0px $light-gray;
  }

  &.save,
  &.reset,
  &.add,
  &.delete,
  &.undo,
  &.view,
  &.edit {
    border: 4px solid black;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 16px;
    text-transform: uppercase;
    // width: 48px;
  }

  &.save {
    background-image: url(../images/icons/save-fg.svg);
  }

  &.edit {
    background-image: url(../images/icons/edit-fg.svg);
  }

  &.reset {
    background-image: url(../images/icons/subtract-fg.svg);
  }

  &.add {
    background-image: url(../images/icons/add-fg.svg);
  }

  &.delete {
    color: $pink;
    background-color: transparent;
    border-color: $pink;

    &.dash {
      &:after {
        content: '';
        width: 20px;
      }

      background-repeat: no-repeat;
      background-position: calc(100% - 12px) 50%;
      background-size: 18px;
      background-image: url(../images/icons/delete.svg);
    }

    &.cancel {
      &:after {
        content: '';
        width: 20px;
      }

      background-repeat: no-repeat;
      background-position: calc(100% - 12px) 50%;
      background-size: 18px;
      background-image: url(../images/icons/x-box.svg);
    }
  }

  &.undo {
    color: $pink;
    background-color: transparent;
    border-color: $blue;

    &:after {
      content: '';
      width: 20px;
    }

    background-repeat: no-repeat;
    background-position: calc(100% - 12px) 50%;
    background-size: 18px;
    background-image: url(../images/icons/x-box.svg);
  }

  &.view {
    background-image: url(../images/icons/view-fg.svg);
  }

  &.not-button {
    border: none;
    padding: 0;
    background: transparent;
    color: $darkest-white;
    margin: 0;
    text-align: left;
    width: auto;
  }
}

/* END BUTTONS   */
