// RESPONSIVE SIZES
$mobile             : 415px;
$tablet             : 768px;
$largeTablet        : 900px;
$desktop            : 1200px;
$largeDesktop       : 1400px;

// Global Variables
$chromeHeaderHeight : 242px;
$chromeFooterHeight : 48px;

// COLORS
$tan                : hsl(20, 26%, 93%);
$pink               : hsl(0, 85%, 67%);
$pink-accent        : hsl(0, 85%, 84%);
$pink-muted         : hsl(0, 43%, 50%);
$gold               : hsl(39, 96%, 48%);
$gold-accent        : hsl(39, 91%, 74%);
$gold-muted         : hsl(39, 96%, 36%);

$blue               : hsl(215, 51%, 69%);
$light-blue         : hsl(215, 51%, 85%);
$brown              : hsl(21, 19%, 57%);
$dark-brown         : hsl(0, 17%, 21%);
$almost-black       : hsl(0, 0%, 20%);
$black              : hsl(0, 0%, 0%);

$white              : hsl(0, 0%, 100%);
$light-white        : hsl(0, 0%, 93%);
$light-gray         : hsl(0, 0%, 90%);
$dark-white         : hsl(0, 0%, 77%);
$darker-white       : hsl(0, 0%, 67%);
$darkest-white       : hsl(0, 0%, 48%);

$out-of-stock       : #DA0000;

$disabled           : hsl(0, 0%, 80%);

$primary            : $pink;
$secondary          : $gold;

$primary-link-color: #1B365D;
$primary-link-color-hover: #2b548f;

// GRADIENTS

$gradient-primary-to-secondary: linear-gradient(30deg, $pink-muted, $pink, $gold, $gold-accent, $light-white, $pink-accent);
$gradient-background: linear-gradient(60deg, $white, $light-white, $light-blue, $blue, $light-white);
$gradient-gold-background: linear-gradient(180deg, hsl(39, 91%, 74%) 0%, hsl(38, 94%, 94%) 33.85%, hsl(0, 0%, 100%) 68.23%);

$black-to-gold-filter: invert(69%) sepia(91%) saturate(3198%) hue-rotate(6deg) brightness(106%) contrast(96%);
$black-to-gold-accent-filter: invert(74%) sepia(31%) saturate(977%) hue-rotate(324deg) brightness(122%) contrast(111%);

// SPLASH LOAD
$splash-opacity     : 0.9;
$splash-fade-in     : 0.7s;
$splash-fade-out    : 1.4s;

@-webkit-keyframes progress-gradientAnimate {
    0%{background-position:0% 75%}
    50%{background-position:100% 26%}
    100%{background-position:0% 75%}
 }
 @-moz-keyframes progress-gradientAnimate {
    0%{background-position:0% 75%}
    50%{background-position:100% 26%}
    100%{background-position:0% 75%}
 }
 @keyframes progress-gradientAnimate {
    0%{background-position:0% 75%}
    50%{background-position:100% 26%}
    100%{background-position:0% 75%}
 }

// PADDING STANDARDS

$mobile-padding-left   : 8px;
$mobile-padding-right  : 8px;
$mobile-padding-top    : 16px;
$mobile-padding-bottom : 12px;

$desktop-padding-left   : 36px;
$desktop-padding-right  : 24px;
$desktop-padding-top    : 48px;
$desktop-padding-bottom : 22px;

$button-bottom-border   : 4px;

// SHADOWS
$shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
$button-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);
$no-shadow: 0px 0px 0px rgba(0, 0, 0, 0);


/*Font Styles*/
/*Font Styles/Links*/
a {
  font-family: 'Rubik';
  font-style: normal;
  color: $black;
  transition: all 0.5s;
  text-shadow: $no-shadow;

  &:hover {
    &:hover {
      text-shadow: $shadow;
    }
    &:active, &:focus {
      text-shadow: $no-shadow;
    }
  }
}

.standard-heading-alt
{
  @extend .standard-heading;
  color: $gold;
}

/*Font Styles*/
/*Font Styles/Headings*/
.standard-heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.standard-heading-alt
{
  @extend .standard-heading;
  color: $gold;
}

// WIDTH UTILITIES
$percentages: (
  '10': 10%,
  '20': 20%,
  '25': 25%,
  '30': 30%,
  '33': 33.33%,
  '40': 40%,
  '50': 50%,
  '60': 60%,
  '66': 66.67%,
  '70': 70%,
  '75': 75%,
  '80': 80%,
  '90': 90%,
  '100': 100%,
);

@each $key, $val in $percentages {
  .w-#{$key} {
    width: #{$val};
  }
}
