@import '../scss/vars';
@import '../scss/mixins';

/*CARDS*/
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: $white;
  border-radius: 1rem;

  &.product-row {
    flex-wrap: wrap;
    header {
      padding: 1rem;
    }
    &.scroll {
      flex-wrap: nowrap;
    }
  }

  &.category-row {
    overflow-x: scroll;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  &,
  .scroll-container {
    position: relative;
  }

  .scroll {
    z-index: 10;

    flex: 1;
    font-size: 100px;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */

    &.right {
      right: -15px;
      top: 30%;
    }

    &.left {
      left: -15px;
      top: 30%;
    }

    .img {
      height: 50%;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  header, .header {
    background: linear-gradient(180deg, #f9cf80 0%, #fef4e1 33.85%, white 68.23%);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    display: block;
  }
  section, .content, .section {
    width: 100%;
    display: block;
    padding: 1rem;
    overflow: hidden;
  }
  footer, .footer {
    width: 100%;
    padding: 1rem;
    display: block;
  }


  app-card {
    position: relative;
    padding: 1rem;
    border: 2px solid $dark-white;
    border-radius: 2rem;
    background: radial-gradient(
        100% 24.15% at 100% 0%, 
        rgba(137, 171, 217, 0.225) 0%, 
        rgba(137, 171, 217, 0) 100%), 
      #FFFFFF;
    height: fit-content;
    width: fit-content;
    min-width: 222px;

    &.category {
      border-radius: 16px;
      width: 250px;
    }

    &.core-product {
      min-width: 340px;
      max-width: 408px;

      @include tablet() {
        min-width: 165px;
        max-width: 198px;
      }

      @include desktop() {
        min-width: 312px;
        max-width: 374px;
      }

      .image {
        border-radius: 0.5rem;
      }
    }

    &.supplier-product {
      width: 224px;

      height: 20rem;
      @include tablet() {
        height: 25rem;
      }
      border-radius: 4px;
    }
  }
}
